<template>
	<v-flex xs12 md12>
		<v-layout wrap>
			<v-flex xs12 md4 gap>
				<v-select
					v-model="model.idpuertolocal"
					id="idpuertolocal"
					height="50"
					:items="ports"
					:label="$t('notificacioncontacto.puerto')"
					solo
					box-shadow="0"
				></v-select>
			</v-flex>
			<v-flex xs12 md4 gap>
				<v-text-field
					height="50"
					:id="`email-notificacioncontacto`"
					v-model="model.email"
					:label="$t('notificacioncontacto.email')"
					toplabel
					solo
					maxlength="250"
					:rules="emailRules"
				></v-text-field>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'magentenotificacionquickedit-form',

	data() {
		return {
			modelName: 'notificacioncontacto',
			ports: [],
			emailRules: [(v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El correo tiene que ser valido']
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		parentModel: {
			type: Object,
			required: true
		}
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
			this.getPuertos();
		},
		getPuertos() {
			this.ports = [];
			let parentPort = this.parentModel.puertos;
			const notificacioncontacto = this.$store.getters.getModelByName(this.modelName);
			const url = notificacioncontacto.url.list;
			this.$puiRequests.postRequest(url, null, (r) => {
				const d = r.data.data;
				for (let pres of d) {
					if (pres.idagente == this.model.idagente) parentPort = parentPort.filter((p) => p.idpuertolocal != pres.idpuertolocal);
				}
				for (let p of parentPort) {
					this.ports.push({ text: p.nombre, value: p.idpuertolocal });
				}
			});
		}
	},
	computed: {
		fixedFilteridimdg() {
			let rules = [];
			this.parentModel.puertos.map((p) => {
				let r = { field: 'idpuertolocal', op: 'eq', data: p.idpuertolocal };
				rules.push(r);
			});
			console.log(rules);
			return {
				groups: [
					{
						groupOp: 'or',
						rules: rules
					}
				],
				groupOp: 'and',
				rules: []
			};
		}
	},
	created() {
		this.getPuertos();
	},
	mounted() {
		//cuando se borra fila, no resta el número de registros, se debe hacer a mano
		this.$puiEvents.$on(`onPui-deleteRow-dataTable-${this.modelName}`, () => {
			this.getPuertos();
			console.log('deleteRow');
			this.$parent.$parent.$refs.table.reloadGrid();
		});

		//cuando se crea una nueva fila
		this.$puiEvents.$on(`onPui-clearSelection-dataTable-${this.modelName}`, () => {
			this.getPuertos();
			console.log('clearSelection-dataTable');
		});
		this.$puiEvents.$on(`onPui-selectItem-dataTable-${this.modelName}`, (r) => {
			console.log('onPui-selectItem-dataTable');
			if (r.length > 0) {
				const key = [Object.keys(r[0])][0][0];
				const selected = r[0][key];
				this.ports.push({ text: selected.nombrepuerto, value: selected.idpuertolocal });
			} else {
				this.getPuertos();
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPui-clearSelection-dataTable-${this.modelName}`);
		this.$puiEvents.$off(`onPui-deleteRow-dataTable-${this.modelName}`);
	}
};
</script>
<style>
.gap {
	padding: 0.5rem;
}

.v-input__slot {
	box-shadow: none !important;
	border: solid 1px var(--N-50);
}
</style>
